import React, { useEffect, useRef } from "react"
import Slider from "react-slick";
import { captureEvent } from 'event-service/modules/capture';

import style from "../vdp.module.scss"

import iconPrev from "../img/left.svg"
import iconNext from "../img/right.svg"
import unavailable from "../../img/slide-01.jpg"

export default function PrimarySlider(props) {
  const slider = useRef(null)

  const { ListOfPhotos, unavailableImg } = props
  const { slide, setSlide } = props

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  useEffect(() => {
    (ListOfPhotos.length > 1) && slider.current.slickGoTo(slide)
  }, [slide])

  const settings = {
    arrows: false,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (old, idx) => {
      setSlide(idx)
    }
  }
  
  return (
    <>
      {(ListOfPhotos.length < 2)
        ?
        (ListOfPhotos.length === 0)
          ?
          <img className={style["main-image"]}
            alt=""
            src={unavailableImg} />
          :
          <img className={style["main-image"]}
            alt=""
            src={ListOfPhotos[0].PhotoUrl}
            onError={(e) => handleImgError(e)} />

        :
        <div style={{ position: "relative"}}>
          <div className="slider-arrow" style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "center"}}>
            <button
              className={style["slides-preview__prev-btn"]}
              style={{ zIndex: "1", position: "relative", opacity: "0.8", backgroundColor: "#fff0"}}
              onClick={(e) => {
                captureEvent({
                  event: 'asc_media_interaction',
                  event_action_result: 'click',
                  affiliation: '',
                  media_type: 'image',
                  link_url: window.location.href,
                  creative_name: '',
                  item_id: document.querySelector('data[id="vin"]')?.value || '',
                  item_number: document.querySelector('data[id="stock"]')?.value || '',
                  item_price: document.querySelector('data[id="price"]')?.value || '',
                  item_condition: document.querySelector('data[id="condition"]')?.value || '',
                  item_year: document.querySelector('data[id="year"]')?.value || '',
                  item_make: document.querySelector('data[id="make"]')?.value || '',
                  item_model: document.querySelector('data[id="model"]')?.value || '',
                  item_variant: document.querySelector('data[id="trim"]')?.value || '',
                  item_color: document.querySelector('data[id="color"]')?.value || '',
                  item_type: document.querySelector('data[id="type"]')?.value || '',
                  item_category: document.querySelector('data[id="category"]')?.value || '',
                  item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
                  item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
                  item_results: document.querySelector('data[id="results"]')?.value || '',
                }, {
                  handlers: ['ASC']
                }).then(res => console.log(res)).catch(err => console.log(err))
                e.stopPropagation()
                setSlide(slide)
                slider.current.slickPrev()
              }}
            >
              <img className={style["slides-preview__prev-btn-img"]} src={iconPrev} style={{ width: "40px", height: "auto", backgroundColor: "rgb(254 254 254 / 60%)", opacity: "0.4", borderRadius: "50%"}} alt="Previous slide" />
            </button>
            <button
              className={style["slides-preview__prev-btn"]}
              style={{ zIndex: "1", position: "relative", marginLeft: "auto", marginRight: "0", opacity: "0.8", backgroundColor: "#fff0"}}
              onClick={(e) => {
                captureEvent({
                  event: 'asc_media_interaction',
                  event_action_result: 'click',
                  affiliation: '',
                  media_type: 'image',
                  link_url: window.location.href,
                  creative_name: '',
                  item_id: document.querySelector('data[id="vin"]')?.value || '',
                  item_number: document.querySelector('data[id="stock"]')?.value || '',
                  item_price: document.querySelector('data[id="price"]')?.value || '',
                  item_condition: document.querySelector('data[id="condition"]')?.value || '',
                  item_year: document.querySelector('data[id="year"]')?.value || '',
                  item_make: document.querySelector('data[id="make"]')?.value || '',
                  item_model: document.querySelector('data[id="model"]')?.value || '',
                  item_variant: document.querySelector('data[id="trim"]')?.value || '',
                  item_color: document.querySelector('data[id="color"]')?.value || '',
                  item_type: document.querySelector('data[id="type"]')?.value || '',
                  item_category: document.querySelector('data[id="category"]')?.value || '',
                  item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
                  item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
                  item_results: document.querySelector('data[id="results"]')?.value || '',
                }, {
                  handlers: ['ASC']
                }).then(res => console.log(res)).catch(err => console.log(err))
                e.stopPropagation()
                setSlide(slide)
                slider.current.slickNext()
              }}
            >
              <img className={style["slides-preview__prev-btn-img"]} src={iconNext} style={{ width: "40px", height: "auto", backgroundColor: "rgb(254 254 254 / 60%)", opacity: "0.4", borderRadius: "50%"}} alt="Next slide" />
            </button>
          </div>
          <Slider {...settings} ref={slider}>
            {
              ListOfPhotos.map((slide, idx) => (
                <img className={style["main-image"]}
                  alt=""
                  src={slide.PhotoUrl}
                  onError={(e) => handleImgError(e)} />
              ))
            }
          </Slider>
        </div>
      }
    </>
  )
}

PrimarySlider.defaultProps = {
  ListOfPhotos: [],
  unavailableImg: unavailable
}